import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './CoachCard.css';

const IMG_PLACEHOLDER = "/playerplaceholder.svg";

export default function CoachCard(props) {
    const location = useLocation();

    return (
        <div className="coachCard">
            <Link to={location.pathname + "/" + props.id}>
                <div className="coachImage">
                    <img src={props.image || IMG_PLACEHOLDER} alt="" />
                </div>
                <div className="slaviaLogo">
                    <img src='/logo.png' alt="" />
                </div>
                <Container>
                    <Row className="coachCardDetails">
                        <Col xs={12}>
                            <Row>
                                <Col sm={12}>
                                    <h3>
                                        <span className="coachCardSurname">{props.firstName}</span> <span className="coachCardSurname">{props.lastName}</span>
                                    </h3>
                                </Col>
                                <Col sm={12}>
                                    <span className="text-muted coachCardPosition">{props.position}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </Link>
        </div>
    );
}