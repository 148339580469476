import { Container, Row, Col } from 'react-bootstrap';
import './PresidentCard.css';

const IMG_PLACEHOLDER = "/playerplaceholder.svg";

export default function PresidentCard(props) {
    return (
        <div className="presidentCard">
            <div className="presidentImage">
                <img src={props.image || IMG_PLACEHOLDER} alt="" />
            </div>
            <div className="slaviaLogo">
                <img src='/logo.png' alt="" />
            </div>
            <Container>
                <Row className="presidentCardDetails">
                    <Col xs={12}>
                        <Row>
                            <Col sm={12}>
                                <h3>
                                    <span className="presidentCardSurname">{props.firstName}</span> <span className="presidentCardSurname">{props.lastName}</span>
                                </h3>
                            </Col>
                            <Col sm={12}>
                                <span className="presidentCardPosition">{props.position}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}