import i18n from './i18n';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import CONSTANTS from './CONSTANTS';

const styles = {
    absoluteWrapper: {
        position: "absolute",
        width: "100%",
        marginTop: "2.5rem",
        zIndex: "-3"
    },
    greyCircle: {
        position: "relative",
        width: "100%",
        paddingBottom: "100%",
        borderRadius:"50%",
        backgroundColor: "#f8f9fa",
        zIndex: "-2"
    },
    image: {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "35%",
        zIndex: "-1",
        transform: "translate(-50%, -50%)"
    },
    text: {
        marginTop: "5rem"
    },
    error: {
        fontSize: "5rem"
    }
};

export default function NoMatch() {
    return (
        <>
            <div style={styles.absoluteWrapper}>
                <Container>
                    <Row>
                        <Col
                            xs={{ span: 6, offset: 5 }}
                            sm={{ span: 5, offset: 5 }}
                            md={{ span: 4, offset: 2 }}
                            className="justify-content-center"
                        >
                            <div style={styles.greyCircle}></div>
                            <Image src="/nomatch.png" style={styles.image} fluid />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container style={{marginTop: "5rem", height: "60vh"}}>
                <Row>
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 5, offset: 5 }}>
                        <h1 style={styles.error}>404</h1>
                        <h4>{i18n.t("err.oopsTitle")}</h4>
                        <p>{i18n.t("err.oopsDescription")}</p>
                        <Link to={CONSTANTS.ROUTES.home.path}>{i18n.t("btn.backToHome")}</Link>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
