import { Col, Container, Row } from 'react-bootstrap';
import i18n from '../i18n';
import './Sponsors.css';

export default function Sponsors(props) {

    function renderContent() {
        return (
            <Container className="sponsors">
                <Row>
                    {
                        props.sponsors.map((s, i) => (
                            <Col sm={6} md={4} key={i}>
                                <a href={s.url} target="_blank" rel="noreferrer">
                                    <div className="sponsor">
                                        <div className="image">
                                            <img
                                                src={s.image}
                                                alt={(i18n.language === 'en' ? s.titleEn : s.titleBg) + ' - ' + i18n.t('officialPartner')}
                                            />
                                        </div>
                                        <div className="title">
                                            <h4>{i18n.language === 'en' ? s.titleEn : s.titleBg}</h4>
                                            <span>{i18n.t('officialPartner')}</span>
                                            <b>🡒</b>
                                        </div>
                                    </div>
                                </a>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        );
    }

    return (
        <>
            <div className="section-header">
                <Container>
                    <h1>{i18n.t('nav.sponsors')}</h1>
                </Container>
            </div>
            {renderContent()}
        </>
    );
}
