import { Col, Container, Row, Button, Spinner, Alert } from 'react-bootstrap';

import CONSTANTS from '../CONSTANTS';
import i18n from '../i18n';
import { useParams, useHistory, useLocation } from "react-router-dom";

import { getPlayerDetails } from '../controller';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

import './PlayerDetails.css';

const MIN_LOADING_TIME = 1000;
const POS = CONSTANTS.POSITIONS;

export default function PlayerDetails() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [playerDetails, setPlayerDetails] = useState(null);

    let { id } = useParams();
    const history = useHistory();
    const location = useLocation();

    function goBack() {
        let path = location.pathname;
        path = path.substr(0, path.lastIndexOf("/"));
        history.push(path);
    }

    useEffect(() => {
        let fetchData = Promise.all([
            getPlayerDetails(id),
            sleep(MIN_LOADING_TIME)
        ]);

        fetchData
            .then(res => {
                setIsLoaded(true);

                if (res[0] && res[0].status === 'success') {
                    setPlayerDetails(res[0].playerDetails);
                } else {
                    setError({ message: i18n.t('err.oopsGeneral') });
                }
            })
            .catch(err => {
                setIsLoaded(true);
                setError({ message: i18n.t('err.oopsGeneral') });
            });
    }, []);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function showError(errMessage) {
        return (
            <Alert variant='danger'>{errMessage}</Alert>
        );
    }

    function showSpinner() {
        return (
            <>
                <div style={{ marginBottom: "16rem" }} />
                <div className="slavia-spinner">
                    <Spinner animation="border" role="status"></Spinner>
                </div>
            </>
        );
    }

    function showMotto(data) {
        if (!(playerDetails.motoEn && playerDetails.motoBg)) {
            return null;
        }

        return (
            <div style={{position: "relative"}}>
                <FontAwesomeIcon className="icon quote-icon" icon={faQuoteLeft} />
                <blockquote className="blockquote">
                    <p>{i18n.language === "en" ? data.motoEn : data.motoBg}</p>
                </blockquote>
            </div>
        )
    }

    function showPlayerDetails(data) {
        if (!data) return;

        return (
            <>
                <Container className="player-header">
                    <Row>
                        <Col>
                            <div className="header-background">
                                <img className="background-image" src='/slaviahall.jpg' alt="" />
                            </div>
                            <div className="details">
                                <div className="number">
                                    {data.number}
                                </div>
                                <div className="first-name">
                                    {i18n.language === "en" ? data.firstNameEn : data.firstNameBg}
                                </div>
                                <div className="last-name">
                                    {i18n.language === "en" ? data.lastNameEn : data.lastNameBg}
                                </div>
                                <div className="position">
                                    {i18n.t(POS[data.position].i18nText)}
                                </div>
                            </div>
                            <img
                                className="player-image"
                                src={data.image ? data.image : '/playerplaceholder.svg'}
                                alt={i18n.language === "en" ? data.firstNameEn + ' ' + data.lastNameEn : data.firstNameBg + ' ' + data.lastNameBg}
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="player-details">
                    <Row>
                        <Col sm={5}>
                            <h2 className="section-title">{i18n.t("details")}</h2>
                            <hr />
                            <Row className="mb-3">
                                <Col xs={4} className="mb-4">
                                    <span className="details-title">{i18n.t("player.height")}</span>
                                    <span className="details-value">{data.height} {i18n.t("player.cm")}</span>
                                </Col>
                                <Col xs={4} className="mb-4">
                                    <span className="details-title">{i18n.t("player.weight")}</span>
                                    <span className="details-value">{data.weight} {i18n.t("player.kg")}</span>
                                </Col>
                                <Col xs={4} className="mb-4">
                                    <span className="details-title">{i18n.t("player.stretch")}</span>
                                    <span className="details-value">{data.stretch} {i18n.t("player.cm")}</span>
                                </Col>
                                <Col xs={12} className="mb-4">
                                    <span className="details-title">{i18n.t("player.dateOfBirth")}</span>
                                    <span className="details-value">{data.dateOfBirth} ({data.age})</span>
                                </Col>
                                <Col xs={12} className="mb-4">
                                    <span className="details-title">{i18n.t("player.placeOfBirth")}</span>
                                    <span className="details-value">{i18n.language === "en" ? data.cityOfBirthEn ? data.cityOfBirthEn : "-" : data.cityOfBirthBg ? data.cityOfBirthBg : "-"}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2} className="vertical-space-lg"></Col>
                        <Col sm={5}>
                            <h2 className="section-title">{i18n.t("aboutMe")}</h2>
                            <hr />
                            <Row className="mb-5">
                                <Col xs={12}>
                                    <div className="about-me">
                                        <span>{i18n.language === "en" ? data.aboutMeEn : data.aboutMeBg}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2} className="vertical-space-lg"></Col>
                        { showMotto(data) }
                    </Row>
                </Container>
            </>
        );
    }

    window.scrollTo(0, 0);

    return (
        <Container className="player-details">
            <Row className="player-details-back">
                <Col xs={6}>
                    <Button variant="light" onClick={goBack}>{"< " + i18n.t('back')}</Button>
                </Col>
            </Row>
            <Row className="player-details-content">
                <Col>
                    {error ? showError(error.message) : !isLoaded ? showSpinner() : showPlayerDetails(playerDetails)}
                </Col>
            </Row>
        </Container>
    );
}
