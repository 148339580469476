import { Form } from 'react-bootstrap';
import i18n from '../i18n';
import './Article.css';

import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function Searchbar(props) {
    const [input, setInput] = useState("");
    const query = new URLSearchParams(useLocation().search);
    const searchCriteria = JSON.parse(decodeURIComponent(query.get("crit")));
    const location = useLocation();

    let history = useHistory();

    useEffect(() => {
        setInput(searchCriteria);
    }, [location]);

    function search(args) {
        if (args.type === "keyup" && args.keyCode !== 13) {
            return;
        }

        let textInput = input.toLowerCase();

        if (textInput === "") {
            return;
        }

        let result = props.articleTitles
            .filter(a => {
                let title = i18n.language === "en" ? a.titleEn : a.titleBg;
                return title.includes(textInput);
            })
            .map(a => a.id);

        history.push('/news/search?res='
            + encodeURIComponent(JSON.stringify(result))
            + '&crit='
            + encodeURIComponent(JSON.stringify(input)));
    }

    return (
        <>
            <Form.Control
                type="text"
                placeholder={i18n.t('search') + '...'}
                className="search"
                onKeyUp={search}
                onInput={e => setInput(e.target.value)}
                value={input || ""}
                autoFocus={!!props.focus}
            />
            <img
                className="search-icon"
                src="/search.png"
                width="24"
                alt=""
                onClick={search}
            />
        </>
    );
}