import { Alert, Col, Container, Row } from 'react-bootstrap';
import NewsCard from './NewsCard';
import Searchbar from './Searchbar';
import { useState, useEffect } from 'react';

import i18n from '../i18n';
import './News.css';

export default function News({ data }) {
    const [articleTitles, setArticleTitles] = useState([]);

    useEffect(() => {
        let temp = data.news.map(a => {
            return {
                id: a.id,
                titleEn: a.titleEn.toLowerCase(),
                titleBg: a.titleBg.toLowerCase()
            };
        });

        setArticleTitles(temp);
    }, []);


    function displayEmpty() {
        return (
            <Col xs={12}>
                <Alert variant='warning'>
                    {i18n.t("noNewsAvailable")}
                </Alert>
            </Col>
        );
    }

    function displayNews(news) {
        return (
            news.map((n, i) => {
                return (
                    <Col xs={12} md={6} lg={4} key={i}>
                        <NewsCard {...n} />
                    </Col>
                );
            })
        );
    }

    return (
        <>
            <div className="section-header">
                <Container>
                    <h1>{i18n.t('nav.news')}</h1>
                </Container>
            </div>
            <Container className="news">
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <Searchbar articleTitles={articleTitles} focus={false} />
                    </Col>
                    <Col xs={6}></Col>
                    {
                        data.news.length ? displayNews(data.news) : displayEmpty()
                    }
                </Row>
            </Container>
        </>
    );
}
