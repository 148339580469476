import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useState } from 'react';

import i18n from '../i18n';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';

import './HallOfFame.css';

const TROPHY_PLACEHOLDER = '/images/trophyplaceholder.png';

export default function HallOfFame(props) {
    const [modalShow, setModalShow] = useState(false);
    const [trophies, setTrophies] = useState(props.trophies);
    const [trophy, setTrophy] = useState(null);

    SwiperCore.use([Navigation, Pagination]);

    function displayTrophy(t, i) {
        let styles = "ml-2 mr-2";

        if (i === 0) styles = "ml-3 mr-2";
        if (i === trophies.length - 1) styles = "ml-2 mr-3";

        return (
            <SwiperSlide key={t.id}>
                <div className={"trophy-wrapper " + styles}>
                    <div className={"trophy"}>
                        <div className="trophy-img">
                            <img
                                src={t.imageUrl ? t.imageUrl : TROPHY_PLACEHOLDER}
                                className={t.imageUrl ? "" : "placeholder"}
                                alt={i18n.language === 'en' ? t.titleEn : t.titleBg}
                            />
                        </div>
                        <div className="trophy-content">
                            <h4>{i18n.language === 'en' ? t.titleEn : t.titleBg}</h4>
                            <Button
                                variant="outline-light"
                                onClick={() => {
                                    setModalShow(true);
                                    setTrophy(t);
                                }}
                            >
                                {i18n.t("more")}
                            </Button>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        );
    }

    function displayTrophies(trophies) {
        if (!trophies.length) {
            return null;
        }

        return (
            <Swiper
                spaceBetween={5}
                slidesPerView={1.15}
                color={"red"}
                navigation
                centeredSlides={true}
                updateOnWindowResize
                watchOverflow
                pagination={{
                    clickable: true
                }}
                breakpoints={{
                    575: {
                        slidesPerView: 2.15,
                        centeredSlides: true
                    },
                    992: {
                        slidesPerView: 3.15,
                        centeredSlides: false
                    },
                    1200: {
                        slidesPerView: 3.15,
                        centeredSlides: false
                    }
                }}
            >
                {trophies.map(displayTrophy)}
            </Swiper>
        );
    }

    function renderModal() {
        if (!trophy) {
            return;
        }

        return (
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="sm"
                centered
                className="trophy-modal"
            >
                <Modal.Header closeButton>
                    <h4>{i18n.language === 'en' ? trophy.titleEn : trophy.titleBg}</h4>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img
                        src={trophy.imageUrl ? trophy.imageUrl : TROPHY_PLACEHOLDER}
                        className={trophy.imageUrl ? "" : "placeholder"}
                        alt={i18n.language === 'en' ? trophy.titleEn : trophy.titleBg}
                    />
                    <p className="pt-3">{i18n.language === 'en' ? trophy.descriptionEn : trophy.descriptionBg}</p>
                </Modal.Body>
            </Modal>
        );
    }

    function renderContent() {
        return (
            <Container className="hall-of-fame mt-5 mb-5">
                <Row>
                    {
                        trophies.map((category, i) => (
                            <Col xs={12} className="mb-4" key={i}>
                                <h2>{i18n.language === 'en' ? category.titleEn : category.titleBg}</h2>
                                <hr />
                                {displayTrophies(category.trophies)}
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        );
    }

    return (
        <>
            <div className="section-header">
                <Container>
                    <h1>{i18n.t('nav.hallOfFame')}</h1>
                </Container>
            </div>
            {renderContent()}
            {renderModal()}
        </>
    );
}
