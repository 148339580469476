import { Col, Container, Row, Alert } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import PlayerCard from './PlayerCard';

import CONSTANTS from '../CONSTANTS';
import i18n from '../i18n';
import { useState } from 'react';

const POS = CONSTANTS.POSITIONS;

export default function PlayersMain({ data }) {
    const [position, setPosition] = useState("all");

    function displayEmpty() {
        return (
            <Alert variant='warning' style={{ marginLeft: "15px" }}>
                {i18n.t("comingsoon")}
            </Alert>
        );
    }

    return (
        <>
            <div className="section-header">
                <Container>
                    <h1>{i18n.language === "en" ? data.category.titleFullEn : data.category.titleFullBg}</h1>
                </Container>
            </div>
            <Container className="filterList">
                <Row>
                    <Col md={6} lg={4}>
                        <Form>
                            <Form.Group controlId="formGridState">
                                <Form.Label>{i18n.t("position")}</Form.Label>
                                <Form.Control as="select" onChange={e => { setPosition(e.target.value) }} custom>
                                    <option value="all" key={0}>
                                        {i18n.t("all")}
                                    </option>
                                    {
                                        Object.keys(POS).map((k, i) => {
                                            let pos = POS[k];

                                            return (
                                                <option value={pos.key} key={i++}>
                                                    {i18n.t(pos.i18nText)}
                                                </option>
                                            );
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row className="mt-3 mb-5">

                    {
                        !data.players.length
                            ? displayEmpty() :
                            data.players
                                .filter(p => position === "all" || position === p.position)
                                .sort((p1, p2) => p1.number > p2.number ? 1 : p1.number < p2.number ? -1 : 0)
                                .map((p, i) => {
                                    return (
                                        <Col xs={12} md={6} lg={4} xl={4} key={i} className="filterListItem">
                                            <PlayerCard
                                                id={p.id}
                                                image={p.image || '/playerplaceholder.svg'}
                                                firstName={i18n.language === "en" ? p.firstNameEn : p.firstNameBg}
                                                lastName={i18n.language === "en" ? p.lastNameEn : p.lastNameBg}
                                                number={p.number}
                                                position={i18n.t(POS[p.position].i18nText)}
                                                age={p.age}
                                                height={p.height}
                                                weight={p.weight}
                                                isVisible={position === "all" || position === p.position}
                                            />
                                        </Col>
                                    );
                                })
                    }

                </Row>
            </Container>
        </>
    );
}
