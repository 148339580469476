import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useLocation } from "react-router-dom";

export default function NavDropdownLink(props) {
    const location = useLocation();

    let path = location.pathname.replace(/\/$/, '');
    var isActive = path.startsWith(props.to);
    var className = isActive ? 'active' : '';
    
    return (
        <NavDropdown.Item as={Link} {...props} className={className}>
            {props.children}
        </NavDropdown.Item>
    );
}