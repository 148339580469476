import { Col, Container, Row } from 'react-bootstrap';

import CONSTANTS from '../CONSTANTS';
import i18n from '../i18n';
import { useState } from 'react';
import PresidentCard from './PresidentCard';

export default function PresidentsMain(props) {
    const [presidents, setPresidents] = useState(props.presidents);

    return (
        <>
            <div className="section-header">
                <Container>
                    <h1>{i18n.t(CONSTANTS.ROUTES.presidents.title)}</h1>
                </Container>
            </div>
            <Container className="mt-5 mb-5">
                <Row>

                    {
                        presidents ? presidents.map((c, i) => (
                            <Col xs={12} md={6} lg={4} xl={4} key={i}>
                                <PresidentCard
                                    id={c.id}
                                    image={c.imageUrl || '/playerplaceholder.svg'}
                                    firstName={i18n.language === "en" ? c.firstNameEn : c.firstNameBg}
                                    lastName={i18n.language === "en" ? c.lastNameEn : c.lastNameBg}
                                    position={i18n.language === "en" ? c.positionEn : c.positionBg}
                                />
                            </Col>
                        )) : null
                    }

                </Row>
            </Container>
        </>
    );
}
