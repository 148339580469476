import { Container, Row, Col } from 'react-bootstrap';
import i18n from '../i18n';
import './Fascilities.css';

export default function Fascilities() {
    return (
        <>
            <div className="section-header">
                <Container>
                    <h1>{i18n.t("nav.fascilities")}</h1>
                </Container>
            </div>
            <Container className="mt-4 mb-5 facilities">
                <Row>
                    <Col xs={12}>
                        <h1 className="mb-4 mb-md-5">{i18n.t("hallTitle")}</h1>
                    </Col>
                    <Col md={5}>
                        <img
                            src="/images/slaviahall.jpg"
                            width="100%"
                            className="mb-4"
                            alt={i18n.t("hallTitle")}
                        />
                    </Col>
                    <Col md={7} className="facilities-content">
                        <Row>
                            <Col sm={12}>
                                <h2 className="section-title">{i18n.t("hallInfoTitle")}</h2>
                                <hr />
                                <Row className="mb-3">
                                    <Col xs={12}>
                                        <p>{i18n.t("hallInfoContent")}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <h2 className="section-title">{i18n.t("hallLocationTitle")}</h2>
                                <hr />
                                <Row className="mb-3">
                                    <Col xs={12}>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.274683223095!2d23.269187315466386!3d42.67672347916682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa85c8b289f289%3A0x5ac0aa53b74a5820!2sVolleyball%20Club%20Slavia%20Sofia!5e0!3m2!1sen!2sgr!4v1633523582675!5m2!1sen!2sgr" width="100%" height="300" style={{ border: 0 }} allowFullScreen="" loading="lazy" title={i18n.t("hallLocationTitle")}></iframe>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <h2 className="section-title">{i18n.t("hallTransportTitle")}</h2>
                                <hr />
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <p>{i18n.t("hallTransport1")}</p>
                                        <ul>
                                            <li>{i18n.t("hallTransport2")}</li>
                                            <li>{i18n.t("hallTransport3")}</li>
                                            <li>{i18n.t("hallTransport4")}</li>
                                            <li>{i18n.t("hallTransport5")}</li>
                                        </ul>
                                    </Col>
                                    <Col md={6}>
                                        <p>{i18n.t("hallTransport6")}</p>
                                    </Col>
                                    <Col xs={12}>
                                        <p><i>{i18n.t("hallTransport7")}</i></p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>

                    </Col>
                </Row>
            </Container>
        </>
    );
}
