import HeaderTop from './HeaderTop';
import HeaderNav from './HeaderNav';

export default function Header(props) {
    return (
        <>
            <div id="header">
                <HeaderTop />
                <HeaderNav playerCategories={props.playerCategories} />
            </div>
        </>
    );
}
