import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './NewsCard.css';
import i18n from '../i18n';
import CONSTANTS from '../CONSTANTS';

export default function NewsCard(props) {
    const location = useLocation();
    let path = location.pathname;
    let i = path.indexOf('/search');
    let url = path === '/' ? CONSTANTS.ROUTES.news.path : path.substring(0, i !== -1 ? i : path.length);
    
    function trim(text) {
        let t = text.substr(0, 70);
        return t.length >= 70 ? t + "..." : t;
    }

    function formatDate(date) {
        let d = date.split("-");

        return d[0] + '/' + d[1] + '/' + d[2] + '\u00a0\u00a0' + d[3] + ':' + d[4];
    }

    return (
        <div className="article">
            <Link to={url + '/' + props.id}>
                <div className="image">
                    <img src={props.imageUrl} alt="" />
                </div>
                <div className="underline" />
                <div className="body">
                    <h4>{i18n.language === "en" ? props.titleEn : props.titleBg}</h4>
                    <span className="text-muted">{formatDate(props.dateCreated)}</span>
                    <p>{i18n.language === "en" ? trim(props.descriptionEn) : trim(props.descriptionBg)}</p>
                </div>
            </Link>
        </div>
    );
}