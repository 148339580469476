import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import './History.css';
import i18n from '../i18n';

const IMG_URL = '/images/history/history';

const styles = {
    timelineItem: {
        color: '#e86971'
    }
};

const historyData = [
    {
        id: 1,
        date: "historyDate1",
        title: "historyTitle1",
        subTitle: "",
        description: "historyContent1",
        imgSrc: IMG_URL + "1.jpg"
    },
    {
        id: 2,
        date: "historyDate2",
        title: "historyTitle2",
        subTitle: "historySubtitle2",
        description: "historyContent2",
        imgSrc: IMG_URL + "2.1.jpg"
    },
    {
        id: 3,
        date: "historyDate3",
        title: "historyTitle3",
        subTitle: "historySubtitle3",
        description: "historyContent3",
        imgSrc: IMG_URL + "3.jpg"
    },
    {
        id: 4,
        date: "historyDate4",
        title: "historyTitle4",
        subTitle: "historySubtitle4",
        description: "historyContent4",
        imgSrc: IMG_URL + "4.jpg"
    },
    {
        id: 5,
        date: "historyDate5",
        title: "historyTitle5",
        subTitle: "historySubtitle5",
        description: "historyContent5",
        imgSrc: IMG_URL + "5.jpg"
    },
    {
        id: 6,
        date: "historyDate6",
        title: "historyTitle6",
        subTitle: "historySubtitle6",
        description: "historyContent6",
        imgSrc: IMG_URL + "6.1.jpg"
    },
    {
        id: 7,
        date: "historyDate7",
        title: "historyTitle7",
        subTitle: "historySubtitle7",
        description: "historyContent7",
        imgSrc: IMG_URL + "7.jpg"
    },
    {
        id: 8,
        date: "historyDate8",
        title: "historyTitle8",
        subTitle: "historySubtitle8",
        description: "historyContent8",
        imgSrc: IMG_URL + "8.1.jpg"
    },
    {
        id: 9,
        date: "historyDate9",
        title: "historyTitle9",
        subTitle: "historySubtitle9",
        description: "historyContent9",
        imgSrc: IMG_URL + "9.jpeg"
    }
];

export default function History() {
    return (
        <>
        <div className="section-header">
            <Container>
                <h1>{i18n.t("historyTitle")}</h1>
            </Container>
        </div>
        <Container>
            <Row>
                <Col>
                    
                    <Timeline className="pt-5" lineColor={'#ddd'} style={styles.timeline}>
                        {
                            historyData.map((entry, i) => {
                                return (
                                    <TimelineItem
                                        key={entry.id.toString()}
                                        dateText={i18n.t(entry.date)}
                                        style={styles.timelineItem}
                                    >
                                        <h1 className={entry.subTitle ? "mb-1" : "mb-3"}>{i18n.t(entry.title)}</h1>
                                        { entry.subTitle ? (<h2 className="mb-3">{i18n.t(entry.subTitle)}</h2>) : null }
                                        <p>{i18n.t(entry.description)}</p>
                                        { entry.imgSrc ? (<img src={entry.imgSrc} className="mt-2" width="100%" alt={i18n.t(entry.title)} />) : null }
                                    </TimelineItem>
                                );
                            })
                        }
                    </Timeline>
                </Col>
            </Row>
        </Container>
        </>
    );
}
