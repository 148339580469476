import Nav from 'react-bootstrap/Nav';
import { Link, useLocation } from "react-router-dom";
import CONSTANTS from '../CONSTANTS';

export default function HeaderNav(props) {
    const location = useLocation();
    const ROUTES = CONSTANTS.ROUTES;

    let path = location.pathname;

    if (path !== ROUTES.home.path) {
        path = path.replace(/\/$/, '');
    }

    var isActive = path === props.to;
    var className = isActive ? 'active' : '';
    
    return (
        <Nav.Link as={Link} {...props} className={className}>
            {props.children}
        </Nav.Link>
    );
}