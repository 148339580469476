import { Alert, Spinner, Col, Button, Row, Table, Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Countdown from 'react-countdown';

import { useLocation, useHistory } from 'react-router-dom';
import { getMatches } from '../controller';

import i18n from '../i18n';
import './Calendar.css';

const MIN_LOADING_TIME = 1000;

export default function Phase(props) {
    const [phase, setPhase] = useState([]);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    let history = useHistory();
    let location = useLocation();

    function goBack() {
        query.delete('tournamentPhase');
        let path = location.pathname + '?' + query;
        history.push(path);
    }

    useEffect(() => {
        let fetchData = Promise.all([
            getMatches(props.phaseId),
            sleep(MIN_LOADING_TIME)
        ]);

        fetchData
            .then(res => {
                if (res[0] && res[0].status === 'success') {
                    setPhase(res[0]);
                } else {
                    setError({ message: i18n.t('err.oopsGeneral') });
                }
                setIsLoaded(true);
            })
            .catch(err => {
                setIsLoaded(true);
                setError({ message: i18n.t('err.oopsGeneral') });
            });
    }, []);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function showError(errMessage) {
        return (
            <Alert variant='danger'>{errMessage}</Alert>
        );
    }

    function showSpinner() {
        return (
            <>
                <div style={{ marginBottom: "5rem" }} />
                <div className="slavia-spinner">
                    <Spinner animation="border" role="status"></Spinner>
                </div>
            </>
        );
    }

    function formatResult(result) {
        if (!result) {
            return (
                <span className="result">
                    <span className="res">-</span>
                    <span> : </span>
                    <span className="res">-</span>
                </span>
            );
        }

        let res = result.split(':');
        let isFirstBigger = res[0] > res[1];

        return (
            <span className="result">
                <span className="res">
                    {isFirstBigger
                        ? (<span className="bigger-res">{res[0]}</span>)
                        : res[0]}
                </span>
                <span> : </span>
                <span className="res">
                    {!isFirstBigger
                        ? (<span className="bigger-res">{res[1]}</span>)
                        : res[1]}
                </span>
            </span>
        );
    }

    function formatGame(game) {
        if (!game) {
            return (<span className="game">-</span>);
        }

        let gameArr = game.split(':');
        let isFirstBigger = gameArr[0] > gameArr[1];

        return (
            <span className="game">
                {isFirstBigger
                    ? (<span className="bigger-res">{gameArr[0]}</span>)
                    : (<span className="text-muted">{gameArr[0]}</span>)}
                -
                {!isFirstBigger
                    ? (<span className="bigger-res">{gameArr[1]}</span>)
                    : (<span className="text-muted">{gameArr[1]}</span>)}
            </span>
        );
    }

    function formatDate(date) {
        let d = date.split("-");

        return d[0] + '/' + d[1] + '/' + d[2] + '\n' + d[3] + ':' + d[4];
    }

    const displayCountdown = ({ days, hours, minutes, seconds }) => {
        return (
            <span>
                {
                    days.toString()
                    + ' : ' +
                    hours.toString().padStart(2, "0")
                    + ' : ' +
                    minutes.toString().padStart(2, "0")
                    + ' : ' +
                    seconds.toString().padStart(2, "0")
                }
            </span>
        );
    };

    function displayRemainingTime(matchDate) {
        let d = matchDate.split("-");
        let date = new Date(d[2], d[1] - 1, d[0], d[3], d[4]);
        let now = new Date();

        if (date - now <= 0) {
            return null;
        }

        return (
            <span className="time-remaining">
                <Countdown date={date} renderer={displayCountdown}></Countdown>
            </span>
        );
    }

    function displayGames(m) {
        return (
            <>
                {formatGame(m.gameOne)}
                {formatGame(m.gameTwo)}
                {formatGame(m.gameThree)}
                {formatGame(m.gameFour)}
                {formatGame(m.gameFive)}
            </>
        );
    }

    function displaySingleMatch(m) {
        return (
            <div className="match">
                <Container fluid>
                    <Row>
                        <Col
                            xs={6} md={1}
                            style={{ display: "flex", alignItems: "center" }}
                            className="match-date-col"
                        >
                            <span>{formatDate(m.date)}</span>
                        </Col>
                        <Col
                            xs={6} md={2}
                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            className="match-location-col"
                        >
                            <span className="match-location">{i18n.language === 'en' ? m.locationEn : m.locationBg}</span>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col xs={12} className="text-center" style={{ marginBottom: "0.75rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <span className="team text-right">{i18n.language === 'en' ? m.hostTeamEn : m.hostTeamBg}</span>
                                    {formatResult(m.result)}
                                    <span className="team text-left">{i18n.language === 'en' ? m.guestTeamEn : m.guestTeamBg}</span>
                                </Col>
                                <Col xs={12} className="text-center">
                                    {!m.result ? displayRemainingTime(m.date) : displayGames(m)}
                                </Col>
                            </Row>
                        </Col>
                        {
                            !m.result ? null : (
                                <Col md={3} className="stats-col" style={{ display: "flex", alignItems: "center" }}>
                                    <Button className="btn-stats pull-right" href={m.statisticsUrl} target="_blank" variant="outline-secondary">
                                        {i18n.t('btn.stats')}
                                    </Button>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </div>
        );
    }

    function displayMatches(matches) {
        return (
            matches.map(m => (
                <tr key={m.id}><td>{displaySingleMatch(m)}</td></tr>
            ))
        );
    }

    function displayRound(round, i) {
        return (
            <div className="round" key={'round' + i}>
                <Table striped bordered size="sm" className="round">
                    <thead><tr><th>
                        {i18n.language === 'en' ? round.roundEn : round.roundBg}
                    </th></tr></thead>
                    <tbody>
                        {displayMatches(round.matches)}
                    </tbody>
                </Table>
            </div>
        );
    }

    function displayPhase(phase) {
        return (
            <Col xs={12}>
                <div className="phase">
                    <h3>{i18n.language === 'en' ? phase.tournamentTitleEn : phase.tournamentTitleBg}</h3>
                    <h4>{i18n.language === 'en' ? phase.phaseTitleEn : phase.phaseTitleBg}</h4>
                    {phase.rounds.map((r, i) => displayRound(r, i))}
                </div>
            </Col>
        );
    }

    window.scrollTo(0, 0);

    if (error) {
        return showError(error.message);
    }

    if (!isLoaded) {
        return showSpinner();
    }

    return (
        <Row>
            <Col xs={6}>
                <Button variant="light" onClick={goBack}>{"< " + i18n.t('back')}</Button>
            </Col>
            {
                !phase.rounds.length
                    ? (
                        <Col xs={12}><Alert className="no-results" variant='danger'>
                            {i18n.t('noResultsFound')}
                        </Alert></Col>
                    )
                    : displayPhase(phase)
            }
        </Row>
    );
}









