import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Alert, Button, Badge } from 'react-bootstrap';
import Countdown from 'react-countdown';
import CONSTANTS from '../CONSTANTS';
import i18n from '../i18n';

import './Home.css';
import NewsCard from '../News/NewsCard';

const BACKGROUND_IMAGE = process.env.PUBLIC_URL + 'images/rainbow-nebula.jpg';
const GHOST_IMAGE = "/men-team.png";
const headerImages = [
    "/team-cover-women.png",
    "/men-team.png"
];
const HEADER_IMAGE_DELAY = 3000;
const NEWS_TO_DISPLAY = 4;

export default function Home(props) {
    const [headerImage, setHeaderImage] = useState("");
    const [news, setNews] = useState([]);
    const [latestMatches, setLatestMatches] = useState([]);
    const [sponsors, setSponsors] = useState([]);

    const history = useHistory();

    function updateHeaderImage(index) {
        setHeaderImage(headerImages[index]);

        let next = (index + 1) % headerImages.length;
        let timeout = setTimeout(() => updateHeaderImage(next), HEADER_IMAGE_DELAY);

        return clearTimeout(timeout);
    }

    useEffect(() => {
        updateHeaderImage(0);

        setNews(props.news.slice(0, NEWS_TO_DISPLAY));
        setLatestMatches(props.latestMatches);
        setSponsors(props.sponsors);
    }, []);

    function formatResult(result) {
        if (!result) {
            return (
                <span className="result">
                    <span className="res">-</span>
                    <span> : </span>
                    <span className="res">-</span>
                </span>
            );
        }

        let res = result.split(':');
        let isFirstBigger = res[0] > res[1];

        return (
            <span className="result">
                <span className="res">
                    {isFirstBigger
                        ? (<span className="bigger-res">{res[0]}</span>)
                        : res[0]}
                </span>
                <span> : </span>
                <span className="res">
                    {!isFirstBigger
                        ? (<span className="bigger-res">{res[1]}</span>)
                        : res[1]}
                </span>
            </span>
        );
    }

    function formatGame(game) {
        if (!game) {
            return (<span className="game">-</span>);
        }

        let gameArr = game.split(':');
        let isFirstBigger = gameArr[0] > gameArr[1];

        return (
            <span className="game">
                {isFirstBigger
                    ? (<span className="bigger-res">{gameArr[0]}</span>)
                    : (<span className="text-muted">{gameArr[0]}</span>)}
                -
                {!isFirstBigger
                    ? (<span className="bigger-res">{gameArr[1]}</span>)
                    : (<span className="text-muted">{gameArr[1]}</span>)}
            </span>
        );
    }

    function formatDate(date) {
        let d = date.split("-");

        return d[0] + '/' + d[1] + '/' + d[2] + '\n' + d[3] + ':' + d[4];
    }

    const displayCountdown = ({ days, hours, minutes, seconds }) => {
        return (
            <span>
                {
                    days.toString()
                    + ' : ' +
                    hours.toString().padStart(2, "0")
                    + ' : ' +
                    minutes.toString().padStart(2, "0")
                    + ' : ' +
                    seconds.toString().padStart(2, "0")
                }
            </span>
        );
    };

    function displayRemainingTime(matchDate) {
        let d = matchDate.split("-");
        let date = new Date(d[2], d[1] - 1, d[0], d[3], d[4]);
        let now = new Date();

        if (date - now <= 0) {
            return null;
        }

        return (
            <span className="time-remaining">
                <Countdown date={date} renderer={displayCountdown}></Countdown>
            </span>
        );
    }

    function displayGames(m) {
        return (
            <>
                {formatGame(m.gameOne)}
                {formatGame(m.gameTwo)}
                {formatGame(m.gameThree)}
                {formatGame(m.gameFour)}
                {formatGame(m.gameFive)}
            </>
        );
    }

    function displayNews(news) {
        return (
            news.map((n, i) => {
                return (
                    <Col xs={12} md={6} key={i}>
                        <NewsCard {...n} />
                    </Col>
                );
            })
        );
    }

    function displayLatestMatches(latestMatches) {
        return (
            latestMatches.map((m, i) => {
                return (
                    <Col xs={12} key={i}>
                        <div className="match mb-4">
                            <Row>
                                <Col xs={12}>
                                    <Badge pill variant="dark">
                                        {i18n.language === 'en' ? m.playerCategoryTitleEn : m.playerCategoryTitleBg}
                                    </Badge>
                                </Col>
                                <Col xs={12}>
                                    <h5 className="mt-2">{i18n.language === 'en' ? m.tournamentTitleEn : m.tournamentTitleBg} | {i18n.language === 'en' ? m.phaseTitleEn : m.phaseTitleBg}</h5>
                                    {i18n.language === 'en' ? m.roundEn : m.roundBg}
                                    <hr className="mb-2" />
                                </Col>
                                <Col
                                    xs={6} md={8}
                                    style={{ display: "flex", alignItems: "center" }}
                                    className="match-date-col"
                                >
                                    <span>{formatDate(m.date)}</span>
                                </Col>
                                <Col
                                    xs={6} md={4}
                                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                    className="match-location-col"
                                >
                                    <span className="match-location">{i18n.language === 'en' ? m.locationEn : m.locationBg}</span>
                                </Col>
                                <Col md={12} className="mt-3">
                                    <Row>
                                        <Col xs={12} className="text-center" style={{ marginBottom: "0.75rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <span className="team text-right">{i18n.language === 'en' ? m.hostTeamEn : m.hostTeamBg}</span>
                                            {formatResult(m.result)}
                                            <span className="team text-left">{i18n.language === 'en' ? m.guestTeamEn : m.guestTeamBg}</span>
                                        </Col>
                                        <Col xs={12} className="text-center">
                                            {!m.result ? displayRemainingTime(m.date) : displayGames(m)}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                );
            })
        );
    }

    function displaySponsors(sponsors) {
        return (
            sponsors.map((s, i) => {
                return (
                    <Col xs={6} md={4} lg={3} key={i}>
                        <a href={s.url} target="_blank" rel="noreferrer">
                            <div className="home-sponsor">
                                <div className="image">
                                    <img
                                        src={s.image}
                                        alt={(i18n.language === 'en' ? s.titleEn : s.titleBg) + ' - ' + i18n.t('officialPartner')}
                                    />
                                </div>
                            </div>
                        </a>
                    </Col>
                );
            })
        );
    }

    function displayEmpty() {
        return (
            <Col xs={12} md={12}>
                <Alert variant='warning'>
                    {i18n.t("noNewsAvailable")}
                </Alert>
            </Col>
        );
    }

    function displayEmptyMatches() {
        return (
            <Col xs={12} md={12}>
                <Alert variant='warning'>
                    {i18n.t("calendar.noResuts")}
                </Alert>
            </Col>
        );
    }

    return (
        <>
            <div id="home-header-line" style={{backgroundImage: `url(${BACKGROUND_IMAGE})`}} />
            <Container id="home">

                <Row>
                    <Col>
                        <div id="home-header">
                            <div id="home-title">
                                <div id="team-photo">
                                    <img className="d-block w-100 ghost-image" src={GHOST_IMAGE} alt="" />
                                    <img className="d-block w-100" src={"/men-team.png"} alt="" />
                                </div>
                                <div className="title" style={{backgroundImage: `url(${BACKGROUND_IMAGE})`}}>
                                    {i18n.t("slaviaSofia")}
                                </div>
                                <div className="subtitle">{i18n.t("volleyballClub")}</div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col xs={12} md={8}>
                        <div id="home-news">
                            <Row>
                                <Col xs={12}>
                                    <h2 className="mb-4">{i18n.t("nav.news")}</h2>
                                </Col>
                                {news.length ? displayNews(news) : displayEmpty()}
                            </Row>
                        </div>

                        <Button className="mt-3" variant="outline-dark" onClick={() => history.push(CONSTANTS.ROUTES.news.path)}>
                            {i18n.t("toAllNews")} {'>'}
                        </Button>
                    </Col>
                    <Col xs={12} md={4} className="mt-5 mt-md-0">
                        <div id="home-match">
                            <Row>
                                <Col xs={12}>
                                    <h2 className="mb-4">{i18n.t("latestMatches")}</h2>
                                </Col>
                                {latestMatches.length ? displayLatestMatches(latestMatches) : displayEmptyMatches()}
                            </Row>
                        </div>

                        <Button className="mt-3" variant="outline-secondary" onClick={() => history.push(CONSTANTS.ROUTES.calendar.path)}>
                            {i18n.t("toCalendar")} {'>'}
                        </Button>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <div id="home-sponsors">
                            <Row>
                                <Col xs={12}>
                                    <h2 className="mb-4">{i18n.t("nav.sponsors")}</h2>
                                </Col>
                                {sponsors.length ? displaySponsors(sponsors) : displayEmptyMatches()}
                            </Row>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    );
}
