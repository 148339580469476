import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useLocation } from "react-router-dom";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width,
        height
    };
}

export default function NavDropdownHoverable(props) {
    const location = useLocation();

    let path = location.pathname.replace(/\/$/, '');
    var isActive = path.startsWith(props.to);
    var className = isActive ? 'active' : '';

    const [windowDimensions] = useState(getWindowDimensions());
    const [isHovered, setIsHovered] = useState(false);

    const hover = function (isHovered) {
        if (windowDimensions.width > 992) {
            setIsHovered(isHovered);
        }
    }

    return (
        <NavDropdown
            {...props}
            onMouseEnter={() => hover(true)}
            onMouseLeave={() => hover(false)}
            onToggle={() => setIsHovered(!isHovered)}
            show={isHovered}
            className={className}
        />
    );
}