var CONSTANTS = {
    ROUTES: {
        home: {
            key: "home",
            title: "nav.home",
            path: "/"
        },
        club: {
            key: "club",
            title: "nav.club",
            path: "/club",
        },
        history: {
            key: "history",
            title: "nav.history",
            path: "/club/history"
        },
        hallOfFame: {
            key: "hallOfFame",
            title: "nav.hallOfFame",
            path: "/club/hallOfFame"
        },
        fascilities: {
            key: "fascilities",
            title: "nav.fascilities",
            path: "/club/fascilities"
        },
        presidents: {
            key: "coachingStaff",
            title: "nav.presidents",
            path: "/club/presidents"
        },
        coachingStaff: {
            key: "presidents",
            title: "nav.coachingStaff",
            path: "/club/coachingStaff"
        },
        athletes: {
            key: "athletes",
            title: "nav.athletes",
            path: "/athletes"
        },
        adolescents: {
            key: "adolescents",
            title: "nav.adolescents",
            path: "/adolescents"
        },
        calendar: {
            key: "calendar",
            title: "nav.calendar",
            path: "/calendar"
        },
        sponsors: {
            key: "sponsors",
            title: "nav.sponsors",
            path: "/sponsors"
        },
        news: {
            key: "news",
            title: "nav.news",
            path: "/news"
        },
        gallery: {
            key: "gallery",
            title: "nav.gallery",
            path: "/gallery"
        }
    },
    POSITIONS: {
        setter: {
            key: "setter",
            i18nText: "player.setter"
        },
        libero: {
            key: "libero",
            i18nText: "player.libero"
        },
        middleBlocker: {
            key: "middleBlocker",
            i18nText: "player.middleBlocker"
        },
        settersDiagonal: {
            key: "settersDiagonal",
            i18nText: "player.settersDiagonal"
        },
        hitter: {
            key: "hitter",
            i18nText: "player.hitter"
        }
    },
    SOCIAL_MEDIA: {
        facebook: {
            url: "https://www.facebook.com/volleyclubslavia"
        },
        instagram: {
            url: "https://example.com"
        },
        maps: {
            url: "https://g.page/slaviavolley?share"
        }
    }
}

export default CONSTANTS;