import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import i18n from '../i18n';

import NavDropdownHoverable from './NavDropdownHoverable';

export default function HeaderTop() {

    const switchLang = () => {
        let newLang = i18n.language === "en" ? "bg" : "en"
        i18n.changeLanguage(newLang).then(() => {
            localStorage.setItem('selectedLanguage', newLang);
        });
    };

    return (
        <Navbar variant="dark" bg="dark" className="justify-content-end" style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem" }}>
            <Nav>
                <NavDropdownHoverable
                    id="nav-dropdown-dark-example"
                    title={i18n.language === "en" ? "English" : "Български"}
                    alignRight={true}
                >
                    <NavDropdown.Item onClick={switchLang}>{i18n.language === "en" ? "Български" : "English"}</NavDropdown.Item>
                </NavDropdownHoverable>
            </Nav>
        </Navbar>
    );
}
