import { Alert, Col, Container, Button, Row } from 'react-bootstrap';
import NewsCard from './NewsCard';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import i18n from '../i18n';
import './News.css';
import Searchbar from './Searchbar';

export default function SearchResults({ data }) {
    const [articleTitles, setArticleTitles] = useState([]);
    const query = new URLSearchParams(useLocation().search);
    const results = JSON.parse(decodeURIComponent(query.get("res")));
    const searchCriteria = JSON.parse(decodeURIComponent(query.get("crit")));

    let history = useHistory();

    useEffect(() => {
        let temp = data.news.map(a => {
            return {
                id: a.id,
                titleEn: a.titleEn.toLowerCase(),
                titleBg: a.titleBg.toLowerCase()
            };
        });

        setArticleTitles(temp);
    }, []);

    const goBack = () => {
        history.goBack()
    }

    function noResults() {
        return (
            <Col xs={12}>
                <Alert variant='warning'>
                    {i18n.t("noResultsFound")}
                </Alert>
            </Col>
        );
    }

    function displayResults(news, results) {
        return (
            news
                .filter(article => results.includes(article.id))
                .map((article, i) => {
                    return (
                        <Col xs={12} md={6} lg={4} key={i}>
                            <NewsCard {...article} />
                        </Col>
                    );
                })
        );
    }

    return (
        <>
            <div className="section-header">
                <Container>
                    <h1>{i18n.t('nav.news')}</h1>
                </Container>
            </div>
            <Container className="news">
                <Row>
                    <Col xs={12}>
                        <Button variant="light" onClick={goBack}>{"< " + i18n.t('back')}</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4} className="mt-3">
                        <Searchbar articleTitles={articleTitles} input={searchCriteria} focus={true} />
                    </Col>
                    <Col xs={12}></Col>
                    {results.length ? displayResults(data.news, results) : noResults()}
                </Row>
            </Container>
        </>
    );
}
