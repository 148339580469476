import { Container, Row, Col } from 'react-bootstrap';
import i18n from '../i18n';
import { Link } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

import CONSTANTS from '../CONSTANTS';
import './Footer.css';

export default function Footer(props) {
    
    const ROUTES = CONSTANTS.ROUTES;
    const SOCIAL_MEDIA = CONSTANTS.SOCIAL_MEDIA;

    const players1 = props.playerCategories.filter(c => c.menu === 1);
    const players2 = props.playerCategories.filter(c => c.menu === 2);

    library.add(faFacebookF, faInstagram);

    function showPlayers(i18nMenuTitle, path, categories) {
        let headerCategories = categories.filter(c => c.parentId === null);

        return (
            <>
                <h5>{i18n.t(i18nMenuTitle)}</h5>
                <ul className="list-unstyled">
                    {
                        headerCategories.map((h, i) => {
                            let subCategories = categories.filter(c => c.parentId === h.id);

                            if (!subCategories.length) {
                                return <li key={i}><Link to={h.path}>{i18n.t(h.i18nTitle)}</Link></li>
                            }

                            return (
                                <li className={i > 0 ? "mt-2" : ""} key={i}>
                                    <h6>{i18n.t(h.i18nTitle)}</h6>
                                    <ul className="list-unstyled">
                                        {
                                            subCategories.map((c, i) => {
                                                return <li key={i}><Link to={c.path}>{i18n.t(c.i18nTitle)}</Link></li>
                                            })
                                        }
                                    </ul>
                                </li>
                            );
                        })
                    }
                </ul>
            </>
        );
    }

    return (
        <div id="footer">
            <Container className="push-bottom">
                <Row className="">
                    <Col xs={12} className="col-md">
                        <Row className="align-items-end">
                            <Col md={12} className="col mb-auto mb-md-3">
                                <img className="footer-logo" src="/slavialogo.png" alt="" />
                            </Col>
                            <Col md={12} className="col">
                                <small className="footer-copyright">
                                    Copyright &#169; 2021
                                    <br />
                                    {i18n.t("allRightsAreReservedTo")} {i18n.t("vcSlavia")}
                                </small>
                            </Col>
                            <Col className="col-12 d-block d-md-none my-3">
                                <hr />
                            </Col>
                        </Row>


                    </Col>
                    <Col xs={5} className="col-md">
                        <h5>{i18n.t("siteMap")}</h5>
                        <ul className="list-unstyled">
                            <li><Link to={ROUTES.home.path}>{i18n.t(ROUTES.home.title)}</Link></li>
                            <li><Link to={ROUTES.calendar.path}>{i18n.t(ROUTES.calendar.title)}</Link></li>
                            <li><Link to={ROUTES.sponsors.path}>{i18n.t(ROUTES.sponsors.title)}</Link></li>
                            <li><Link to={ROUTES.news.path}>{i18n.t(ROUTES.news.title)}</Link></li>
                        </ul>
                    </Col>
                    <Col xs={7} className="col-md">
                        <h5>{i18n.t("nav.club")}</h5>
                        <ul className="list-unstyled text-small">
                            <li><Link to={ROUTES.history.path}>{i18n.t(ROUTES.history.title)}</Link></li>
                            <li><Link to={ROUTES.hallOfFame.path}>{i18n.t(ROUTES.hallOfFame.title)}</Link></li>
                            <li><Link to={ROUTES.coachingStaff.path}>{i18n.t(ROUTES.coachingStaff.title)}</Link></li>
                            <li><Link to={ROUTES.fascilities.path}>{i18n.t(ROUTES.fascilities.title)}</Link></li>
                            <li><Link to={ROUTES.presidents.path}>{i18n.t(ROUTES.presidents.title)}</Link></li>
                        </ul>
                    </Col>
                    <Col xs={5} className="col-md">
                        {showPlayers("nav.athletes", ROUTES.athletes.path, players1)}
                        {showPlayers("nav.young-athletes", ROUTES.adolescents.path, players2)}
                    </Col>
                    <Col xs={7} className="col-md">
                        <h5>{i18n.t("contactUs")}</h5>
                        <h6 className="mb-4">{i18n.t("vcSlavia")}</h6>
                        <p>+359 89 849 5050</p>
                        <p>+359 89 964 6762</p>
                        <p>volleyslavia@gmail.com</p>
                        <a href={SOCIAL_MEDIA.facebook.url} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="icon" icon={['fab', 'facebook-f']} size="2x" />
                        </a>
                        <a href={SOCIAL_MEDIA.instagram.url} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="icon" icon={['fab', 'instagram']} size="2x" />
                        </a>
                        <a href={SOCIAL_MEDIA.maps.url} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="icon" icon={faMapMarkedAlt} size="2x" />
                        </a>
                    </Col>
                    <Col xs={12}>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}