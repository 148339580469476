import React from 'react';
import ReactDOM from 'react-dom'
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import App from './App';
import { fetchData } from './controller';

require('dotenv').config()

setLanguage();
supressWarning();

const loadData = Promise.all([
  fetchData(),
  sleep(2400) // min loading time
]);

loadData
  .then(res => onDataLoad(res[0], null))
  .catch(err => onDataLoad(null, err));

function onDataLoad(data, err) {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <App hideLoader={hideLoader} data={data} error={err} />
    </I18nextProvider>,

    document.getElementById('root')
  );
}

function setLanguage() {
  var lang = localStorage.getItem('selectedLanguage');

  if (lang && lang !== i18n.language) {
    i18n.changeLanguage(lang);
  }
}

function supressWarning() {
  const backup = console.warn;

  console.warn = function filterWarnings(msg) {
    const supressedWarnings = ['\'i18n\' is assigned a value but never used  no-unused-vars'];

    if (!supressedWarnings.some(entry => msg.includes(entry))) {
      backup.apply(console, arguments);
    }
  };
}

function hideLoader() {
  document.querySelector('.loader').classList.add('loader--hide');
  document.querySelector('.loader-logo').classList.add('loader-logo--hide');
  document.querySelector('.lds-ellipsis').classList.add('lds-ellipsis--hide');

  setTimeout(() => {
    document.querySelector('.loader').classList.add('loader--hidden');
  }, 1300);
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}