const BASE_URL = process.env.REACT_APP_BASE_URL || "https://slaviavolleyapi.pressrelease.bg/";

const _fetch = async function (operationId, config) {
    const res = await fetch(BASE_URL + operationId, config);
    return await res.json();
}

export function fetchData() {
    return Promise.all([
        _fetch('getPlayers'),
        _fetch('getPlayerCategories'),
        _fetch('getPlayerPositions'),
        _fetch('getNews'),
        _fetch('getSeasons'),
        _fetch('getSponsors'),
        getLatestMatches(),
        _fetch('getCoaches'),
        _fetch('getPresidents'),
        _fetch('getTrophies')
    ]).then(data => {
        if (data.every(entry => entry.status === 'error')) {
            throw Error('Data load failure!');
        }

        return {
            players: data[0].players,
            categories: data[1].categories,
            positions: data[2].positions,
            news: data[3].news,
            seasons: data[4].seasons,
            sponsors: data[5].sponsors,
            latestMatches: data[6].matches,
            coaches: data[7].coaches,
            presidents: data[8].presidents,
            trophies: data[9].trophies
        }
    });
};

export function filterPlayers(allPlayers, categoryId) {
    return allPlayers.filter(p => p.categoryId === categoryId);
};

export function getPlayerDetails(playerId) {
    return _fetch('getPlayerDetails', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            playerId: playerId
        })
    });
}

export function getArticle(articleId) {
    return _fetch('getArticle', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            articleId: articleId
        })
    });
}

export function getSeasons() {
    return _fetch('getSeasons');
}

export function getTournaments(seasonId, categoryId) {
    return _fetch('getTournaments', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            seasonId: seasonId,
            categoryId: categoryId
        })
    });
}

export function getMatches(phaseId) {
    return _fetch('getMatches', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            phaseId: phaseId
        })
    });
}

async function getLatestMatches() {
    return _fetch('getLatestMatches', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            limit: 2
        })
    });
}