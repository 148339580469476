import { Col, Container, Row, Button } from 'react-bootstrap';

import CONSTANTS from '../CONSTANTS';
import i18n from '../i18n';
import { useHistory } from "react-router-dom";

import { useState } from 'react';
import './CoachDetails.css';

export default function CoachDetails(props) {
    const [coachDetails, setCoachDetails] = useState(props.coach);

    const history = useHistory();

    function goBack() {
        history.push(CONSTANTS.ROUTES.coachingStaff.path);
    }

    function showCoachDetails(data) {
        if (!data) return;

        return (
            <Container className="mb-5">
                <Row>
                    <Col md={4}>
                        <div>
                            <img
                                className="coach-image"
                                src={data.imageUrl || '/playerplaceholder.svg'}
                                width="100%"
                                alt={i18n.language === "en" ? data.firstNameEn + ' ' + data.lastNameEn : data.firstNameBg + ' ' + data.lastNameBg}
                            />
                        </div>
                    </Col>
                    <Col md={8} className="coach-details mt-3 mt-md-0">
                        <Row>
                            <Col xs={12}>
                                <div className="first-name">
                                    {i18n.language === "en" ? data.firstNameEn : data.firstNameBg}
                                </div>
                                <div className="last-name">
                                    {i18n.language === "en" ? data.lastNameEn : data.lastNameBg}
                                </div>
                                <div className="position">
                                    {i18n.language === "en" ? data.positionEn : data.positionBg}
                                </div>
                            </Col>
                            <Col sm={12} className="mt-5">
                                <h2 className="section-title">{i18n.t("career")}</h2>
                                <hr />
                                <Row>
                                    <Col xs={12}>
                                        <div className="article-content"
                                            dangerouslySetInnerHTML={{
                                                __html: i18n.language === "en" ? data.careerEn : data.careerBg
                                            }}></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }

    window.scrollTo(0, 0);

    return (
        <Container className="coach-details">
            <Row className="coach-details-back mt-4">
                <Col xs={6}>
                    <Button variant="light" onClick={goBack}>{"< " + i18n.t('back')}</Button>
                </Col>
            </Row>
            <Row className="coach-details-content">
                <Col>
                    <div className="slaviaLogo">
                        <img src='/logo.png' alt="" />
                    </div>
                    {showCoachDetails(coachDetails)}
                </Col>
            </Row>
        </Container>
    );
}
