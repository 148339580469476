import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Container } from 'react-bootstrap';

import CONSTANTS from './CONSTANTS';
import { filterPlayers } from './controller';
import './App.css';

import Header from './Header/Header';
import Home from '../src/Home/Home';
import History from '../src/Club/History';
import HallOfFame from '../src/Club/HallOfFame';
import Fascilities from './Club/Fascilities';
import PlayersMain from './Athletes/PlayersMain';
import PlayerDetails from './Athletes/PlayerDetails';
import Calendar from './Calendar/Calendar';
import Sponsors from './Sponsors/Sponsors';
import News from './News/News';
import SearchResults from './News/SearchResults';
import Article from './News/Article';
import NoMatch from '../src/NoMatch';
import Footer from './Footer/Footer';
import ScrollToTop from './ScrollToTop';
import CoachesMain from './Club/CoachesMain';
import CoachDetails from './Club/CoachDetails';
import PresidentsMain from './Club/PresidentsMain';

export default function App({ hideLoader, data, error }) {
  const { i18n } = useTranslation();

  useEffect(hideLoader);

  if (error) {
    return (
      <Container>
        <h1>503</h1>
        <hr />
        <p>Service Unavailable</p>
      </Container>
    );
  }

  let playerCategories = [];

  data.categories.forEach(c => {
    let menu = c.menu === 1 ? CONSTANTS.ROUTES.athletes.path : CONSTANTS.ROUTES.adolescents.path;
    let parentKey = c.parentId ? data.categories.find(cc => cc.id === c.parentId).key : "";
    let navKey = parentKey ? parentKey + '-' + c.key : c.key;
    let i18nTitle = "nav." + navKey;
    let i18nTitleFull = "nav." + navKey + "-full";
    let path = menu + '/' + (parentKey ? parentKey + '/' : "") + c.key;

    CONSTANTS.ROUTES[navKey] = {
      key: navKey,
      title: "nav." + (c.parentId ? data.categories.find(cc => cc.id === c.parentId).key + "." : "") + c.key,
      path: path,
      menu: c.menu
    };

    i18n.addResource("en", "translations", i18nTitle, c.titleEn);
    i18n.addResource("bg", "translations", i18nTitle, c.titleBg);
    i18n.addResource("en", "translations", i18nTitleFull, c.titleFullEn);
    i18n.addResource("bg", "translations", i18nTitleFull, c.titleFullBg);

    playerCategories.push({
      ...c, parentKey, navKey, i18nTitle, i18nTitleFull, path
    });
  });

  const ROUTES = CONSTANTS.ROUTES;

  return (
    <Router>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
        integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
        crossOrigin="anonymous"
      />

      <div id="content-wrapper">

        <Header playerCategories={playerCategories} />

        <ScrollToTop />

        <Switch>
          <Route exact path={ROUTES.home.path}>
            <Home news={data.news} latestMatches={data.latestMatches} sponsors={data.sponsors} />
          </Route>
          <Route exact path={ROUTES.history.path}>
            <History />
          </Route>
          <Route exact path={ROUTES.hallOfFame.path}>
            <HallOfFame trophies={data.trophies} />
          </Route>
          <Route exact path={ROUTES.coachingStaff.path}>
            <CoachesMain coaches={data.coaches} />
          </Route>
          {
            data.coaches.map((c, i) => {
              return (
                <Route exact path={ROUTES.coachingStaff.path + "/" + c.id} key={'coachroute' + i}>
                  <CoachDetails coach={c} />
                </Route>
              );
            })
          }
          <Route exact path={ROUTES.presidents.path}>
            <PresidentsMain presidents={data.presidents} />
          </Route>
          <Route exact path={ROUTES.fascilities.path}>
            <Fascilities />
          </Route>
          {
            playerCategories.map((c, i) => {
              let players = filterPlayers(data.players, c.id);
              let obj = {
                category: c,
                players: players
              };

              return (

                <Route exact path={c.path} key={'playerscatroute' + i}>
                  <PlayersMain data={obj} />
                </Route>

              );
            })
          }
          {
            playerCategories.map((c, i) => {
              let players = filterPlayers(data.players, c.id);
              let obj = {
                players: players
              };

              return (

                <Route exact path={c.path + "/:id"} key={'playerroute' + i}>
                  <PlayerDetails data={obj} />
                </Route>

              );
            })
          }
          <Route exact path={ROUTES.calendar.path}>
            <Calendar categories={playerCategories} seasons={data.seasons} />
          </Route>
          <Route exact path={ROUTES.sponsors.path}>
            <Sponsors sponsors={data.sponsors} />
          </Route>
          <Route exact path={ROUTES.news.path}>
            <News data={{ news: data.news }} />
          </Route>
          <Route exact path={ROUTES.news.path + '/search'}>
            <SearchResults data={{ news: data.news }} />
          </Route>
          <Route exact path={ROUTES.news.path + '/:id'}>
            <Article />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>

      </div>

      <Footer playerCategories={playerCategories} />

    </Router>
  );
}
