import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './PlayerCard.css';
import i18n from '../i18n';

export default function PlayerCard(props) {
    const location = useLocation();

    return (
        <div className="playerCard">
            <Link to={location.pathname + "/" + props.id}>
                <div className="playerNumber">
                    <span>{props.number}</span>
                </div>
                <div className="playerImage">
                    <img src={props.image} alt="" />
                </div>
                <div className="slaviaLogo">
                    <img src='/logo.png' alt="" />
                </div>
                <Container>
                    <Row className="playerCardDetails">
                        <Col xs={7}>
                            <Row>
                                <Col sm={12}>
                                    <h3>
                                        <span>{props.firstName}</span>
                                        <br />
                                        <span className="playerCardSurname">{props.lastName}</span>
                                    </h3>
                                </Col>
                                <Col sm={12}>
                                    <span className="text-muted playerCardPosition">{props.position}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={5} className="playerCardInfoRight">
                            <Row>
                                <Col xs={8} className="text-right">
                                    <span className="text-muted">{i18n.t("player.ag")} </span>
                                </Col>
                                <Col xs={4} className="playerStat">
                                    <span>{props.age}</span>
                                </Col>
                                <Col xs={8} className="text-right">
                                    <span className="text-muted">{i18n.t("player.ht")} </span>
                                </Col>
                                <Col xs={4} className="playerStat">
                                    <span>{props.height}</span>
                                </Col>
                                <Col xs={8} className="text-right">
                                    <span className="text-muted">{i18n.t("player.wt")} </span>
                                </Col>
                                <Col xs={4} className="playerStat">
                                    <span>{props.weight}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </Link>
        </div>
    );
}