import { Alert, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { getTournaments } from '../controller';

import { useHistory, useLocation } from 'react-router-dom';
import i18n from '../i18n';
import Tournaments from './Tournaments';
import './Calendar.css';
import Phase from './Phase';

const MIN_LOADING_TIME = 700;

export default function Calendar(props) {
    const [seasons, setSeasons] = useState(props.seasons);
    const [seasonId, setSeasonId] = useState(seasons[0].id);

    const [categories, setCategories] = useState(props.categories.filter(c => c.parentId === null));
    const [categoryId, setCategoryId] = useState(categories[0].id);

    const [tournaments, setTournaments] = useState([]);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    let location = useLocation();
    let history = useHistory();

    if (!query.get("season") || !query.get("category")) {
        let path = location.pathname;

        if (!path.endsWith('/')) {
            path = path + '/';
        }

        history.push(path + '?season=' + seasonId + '&category=' + categoryId);
    }

    useEffect(() => {
        setSeasonId(query.get('season'));
        setCategoryId(query.get('category'));

        setIsLoaded(false);

        let fetchData = Promise.all([
            getTournaments(query.get('season') || seasonId, query.get('category') || categoryId),
            sleep(MIN_LOADING_TIME)
        ]);

        fetchData
            .then(res => {
                if (res[0] && res[0].status === 'success') {
                    setTournaments(res[0].tournaments);
                } else {
                    setError({ message: i18n.t('err.oopsGeneral') });
                }
                setIsLoaded(true);
            })
            .catch(err => {
                setIsLoaded(true);
                setError({ message: i18n.t('err.oopsGeneral') });
            });
    }, [seasonId, categoryId]);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function showError(errMessage) {
        return (
            <Alert variant='danger'>{errMessage}</Alert>
        );
    }

    function showSpinner() {
        return (
            <Container className="calendar" style={{marginBottom: "6rem"}}>
                <Row>
                    <Col>
                        <Form className="calendar-filter">
                            <Row>
                                <Col md={6} lg={4}>
                                    {showSeasonSelect()}
                                </Col>
                                <Col md={6} lg={4}>
                                    {showCategorySelect()}
                                </Col>
                                <Col md={6} lg={4}>
                                    <div className="slavia-spinner">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }

    function changeSeason(e) {
        let id = e.target.value;
        let path = location.pathname;

        if (!path.endsWith('/')) {
            path = path + '/';
        }

        setSeasonId(id);
        history.push(path + '?season=' + id + '&category=' + categoryId);
    }

    function showSeasonSelect() {
        return (
            <Form.Group controlId="formGridState">
                <Form.Label>{i18n.t("season")}</Form.Label>
                <Form.Control value={seasonId} as="select" onChange={changeSeason} custom>
                    {
                        seasons.map((s, i) => (
                            <option value={s.id} key={i + 1}>
                                {i18n.t(s.startYear + '/' + s.endYear)}
                            </option>
                        ))
                    }
                </Form.Control>
            </Form.Group>
        );
    }

    function changeCategory(e) {
        let id = e.target.value;
        let path = location.pathname;

        if (!path.endsWith('/')) {
            path = path + '/';
        }

        setCategoryId(id);
        history.push(path + '?season=' + seasonId + '&category=' + id);
    }

    function showCategorySelect() {
        return (
            <Form.Group controlId="formGridState">
                <Form.Label>{i18n.t("category")}</Form.Label>
                <Form.Control value={categoryId} as="select" onChange={changeCategory} custom>
                    {
                        categories.map((c, i) => (
                            <option value={c.id} key={i + 1}>
                                {i18n.t(c.i18nTitle)}
                            </option>
                        ))
                    }
                </Form.Control>
            </Form.Group>
        );
    }

    function showCalendar() {
        return (
            <Container className="calendar">
                <Row>
                    <Col>
                        <Form className="calendar-filter">
                            <Row>
                                <Col md={6} lg={4}>
                                    {showSeasonSelect()}
                                </Col>
                                <Col md={6} lg={4}>
                                    {showCategorySelect()}
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            query.get("tournamentPhase")
                                ? <Phase phaseId={query.get("tournamentPhase")} />
                                : <Tournaments data={tournaments} />
                        }
                    </Col>
                </Row>
            </Container>
        );
    }

    function renderContent() {
        if (error) {
            return showError(error.message);
        }

        if (!isLoaded) {
            return showSpinner();
        }

        return showCalendar();
    }

    return (
        <>
            <div className="section-header">
                <Container>
                    <h1>{i18n.t('nav.calendar')}</h1>
                </Container>
            </div>
            {renderContent()}
        </>
    );
}
