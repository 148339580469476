import { Alert, Table } from 'react-bootstrap';
import { useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import i18n from '../i18n';
import './Calendar.css';

export default function Tournaments(props) {
    const [tournaments, setSeasons] = useState(props.data);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    let location = useLocation();

    function showTournament(t) {
        return (
            <Table striped bordered hover size="sm" key={'tournament' + t.id}>
                <thead><tr><th>{i18n.language === 'en' ? t.titleEn : t.titleBg}</th></tr></thead>
                <tbody>
                    {
                        t.phases.map(p => displayPhase(p))
                    }
                </tbody>
            </Table>
        );
    }

    function displayPhase(p) {
        query.set('tournamentPhase', p.id);
        let linkURL = location.pathname + '?' + query;

        return (
            <tr key={'phase' + p.id}><td>
                <Link to={linkURL}><div>{i18n.language === 'en' ? p.titleEn : p.titleBg}</div></Link>
            </td></tr>
        );
    }

    function displayEmpty() {
        return (
            <Alert variant='warning'>
                {i18n.t("calendar.noResuts")}
            </Alert>
        );
    }

    return (
        <div className="tournaments">
            {!tournaments.length ? displayEmpty() : tournaments.map(t => showTournament(t))}
        </div>
    );
}









