import { Container, Row, Col, Alert, Button, Spinner } from 'react-bootstrap';
import i18n from '../i18n';
import { useParams, useHistory } from "react-router-dom";
import './Article.css';
import { FacebookShareButton, FacebookIcon } from "react-share";

import { getArticle } from '../controller';
import { useState, useEffect } from 'react';

const MIN_LOADING_TIME = 1000;

export default function Article() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [article, setArticle] = useState(null);

    let { id } = useParams();
    let history = useHistory();
    let URL = window.location.href;

    const goBack = () => {
        history.goBack()
    }

    useEffect(() => {
        let fetchData = Promise.all([
            getArticle(id),
            sleep(MIN_LOADING_TIME)
        ]);

        fetchData
            .then(res => {
                setIsLoaded(true);

                if (res[0] && res[0].status === 'success') {
                    setArticle(res[0].article);
                } else {
                    setError({ message: i18n.t('err.oopsGeneral') });
                }
            })
            .catch(err => {
                setIsLoaded(true);
                setError({ message: i18n.t('err.oopsGeneral') });
            });
    }, []);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function showError(errMessage) {
        return (
            <Alert variant='danger'>{errMessage}</Alert>
        );
    }

    function showSpinner() {
        return (
            <>
                <div style={{ marginBottom: "16rem" }} />
                <div className="slavia-spinner">
                    <Spinner animation="border" role="status"></Spinner>
                </div>
            </>
        );
    }

    function formatDate(date) {
        let d = date.split("-");

        return d[0] + '/' + d[1] + '/' + d[2] + '\u00a0\u00a0' + d[3] + ':' + d[4];
    }

    function shareSection() {
        return (
            <div className="social">
                <FacebookShareButton url={URL}>
                    <FacebookIcon size={48} />
                </FacebookShareButton>
            </div>
        );
    }

    function showArticle(data) {
        if (!data) return;
        return (
            <Container className="article-full mb-5">
                <Row>
                    <Col xs={12} lg={10}>
                        <h1>{i18n.language === "en" ? data.titleEn : data.titleBg}</h1>
                        <span className="text-muted">{formatDate(data.dateCreated)}</span>
                    </Col>
                    <Col xs={12}>
                        <img
                            src={data.imageUrl}
                            width="100%"
                            alt={i18n.t('nav.news') + ' - ' + i18n.language === "en" ? data.titleEn : data.titleBg}
                        />
                        <div className="article-content mt-5 mb-5"
                            dangerouslySetInnerHTML={{
                                __html: i18n.language === "en" ? data.contentEn : data.contentBg
                            }}></div>

                        {shareSection()}
                    </Col>
                </Row >
            </Container >
        );
    }

    window.scrollTo(0, 0);

    return (
        <Container>
            <Row className="article-back">
                <Col xs={6}>
                    <Button variant="light" onClick={goBack}>{"< " + i18n.t('back')}</Button>
                </Col>
            </Row>
            <Row className="article-content">
                <Col>
                    {
                        error ? showError(error.message) : !isLoaded ? showSpinner() : showArticle(article)
                    }
                </Col>
            </Row>
        </Container>
    );
}