import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import i18n from '../i18n';
import { Link } from "react-router-dom";

import NavDropdownHoverable from './NavDropdownHoverable';
import NavDropdownLink from './NavDropdownLink';
import NavLink from './NavLink';
import CONSTANTS from '../CONSTANTS';
import './HeaderNav.css'
import { Container, Dropdown } from 'react-bootstrap';

const ROUTES = CONSTANTS.ROUTES;
const breakpoint_lg = 992;

export default function HeaderNav(props) {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerWidth >= breakpoint_lg) {
                setIsExpanded(false);
                document.body.classList.remove('no-scroll');
                document.getElementById('header').classList.remove('scroll');
            }
        });
    }, []);

    const updateScroll = () => {
        if (!isExpanded) {
            document.body.classList.add('no-scroll');
            document.getElementById('header').classList.add('scroll');
        } else {
            document.body.classList.remove('no-scroll');
            document.getElementById('header').classList.remove('scroll');
        }
    };

    const onSelect = () => {
        setTimeout(() => {
            setIsExpanded(false);
            updateScroll();
        }, 100);
    };

    const toggle = () => {
        setIsExpanded(!isExpanded);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        updateScroll();
    };

    const players1 = props.playerCategories.filter(c => c.menu === 1);
    const players2 = props.playerCategories.filter(c => c.menu === 2);

    function showPlayersDropdown(i18nMenuTitle, path, categories) {
        let headerCategories = categories.filter(c => c.parentId === null);

        return (
            <NavDropdownHoverable title={i18n.t(i18nMenuTitle)} to={path}>
                {
                    headerCategories.map((h, i) => {
                        let subCategories = categories.filter(c => c.parentId === h.id);

                        if (!subCategories.length) {
                            return (
                                <NavDropdownLink to={h.path} onClick={onSelect} key={'navdropdown' + i}>
                                    {i18n.t(h.i18nTitle)}
                                </NavDropdownLink>
                            );
                        }

                        return (
                            <div key={'headernavdropdown' + i}>
                                <Dropdown.Header>{i18n.t(h.i18nTitle)}</Dropdown.Header>
                                {

                                    subCategories.map((c, i) => {
                                        return (
                                            <NavDropdownLink to={c.path} onClick={onSelect} key={i}>
                                                <span className="pad-left">{i18n.t(c.i18nTitle)}</span>
                                            </NavDropdownLink>
                                        )

                                    })
                                }
                            </div>
                        )
                    })
                }
            </NavDropdownHoverable>
        );
    }

    return (
        <Navbar expand="lg" bg="light" variant="light" id="main-navbar" expanded={isExpanded}>
            <Container fluid>

                <Navbar.Brand as={Link} to={ROUTES.home.path}>
                    <img
                        src="/slavialogo.png"
                        width="92"
                        height="108"
                        className="d-inline-block align-top"
                        alt="Slavia VK"
                    />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggle} />

                <Navbar.Collapse id="main-navbar-nav" className="align-self-end">
                    <Nav className="me-auto">

                        <NavLink to={ROUTES.home.path} onClick={onSelect}>
                            {i18n.t(ROUTES.home.title)}
                        </NavLink>

                        <NavDropdownHoverable title={i18n.t(ROUTES.club.title)} to={ROUTES.club.path}>
                            <NavDropdownLink to={ROUTES.history.path} onClick={onSelect}>
                                {i18n.t(ROUTES.history.title)}
                            </NavDropdownLink>
                            <NavDropdownLink to={ROUTES.hallOfFame.path} onClick={onSelect}>
                                {i18n.t(ROUTES.hallOfFame.title)}
                            </NavDropdownLink>
                            <NavDropdownLink to={ROUTES.presidents.path} onClick={onSelect}>
                                {i18n.t(ROUTES.presidents.title)}
                            </NavDropdownLink>
                            <NavDropdownLink to={ROUTES.coachingStaff.path} onClick={onSelect}>
                                {i18n.t(ROUTES.coachingStaff.title)}
                            </NavDropdownLink>
                            <NavDropdownLink to={ROUTES.fascilities.path} onClick={onSelect}>
                                {i18n.t(ROUTES.fascilities.title)}
                            </NavDropdownLink>
                        </NavDropdownHoverable>

                        {showPlayersDropdown("nav.athletes", ROUTES.athletes.path, players1)}
                        {showPlayersDropdown("nav.young-athletes", ROUTES.adolescents.path, players2)}

                        <NavLink to={ROUTES.calendar.path} onClick={onSelect}>
                            {i18n.t(ROUTES.calendar.title)}
                        </NavLink>

                        <NavLink to={ROUTES.sponsors.path} onClick={onSelect}>
                            {i18n.t(ROUTES.sponsors.title)}
                        </NavLink>

                        <NavLink to={ROUTES.news.path} onClick={onSelect}>
                            {i18n.t(ROUTES.news.title)}
                        </NavLink>

                    </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
}